<template>
  <div>
    <div class="bg1">
      <div class="page_title_contain">
        <header
          class="other_page_head"
          style="background: none; box-shadow: none"
        >
          <div class="common_warp header_wrap">
            <div
              class="icon"
              @click="
                () => {
                  $router.push('/home')
                }
              "
            >
              <img src="../assets/eamaster_logo_title_index.png" alt="" />
            </div>

            <div class="language other_page_language">
              <span
                class="pointer"
                @click="
                  () => {
                    $router.push('/')
                  }
                "
                >中文</span
              ><span class="line">|</span
              ><span
                class="pointer"
                @click="
                  () => {
                    $router.push('english')
                  }
                "
                >English</span
              >
            </div>
          </div>
        </header>
      </div>
    </div>
    <div class="about">
      <div class="title">About US</div>
      <div class="text">
        EAmaster is an information platform for information on extracurricular
        activities for students in grades 7 to 12, dedicated to providing a
        reliable and extensive directory of enrichment programs for youth
        preparing to study abroad. We gather extracurricular activities for
        students from a variety of sources. The activities on our website and
        WeChat mini program, which span over ten categories including summer
        programs, academic competitions, business competitions, scientific
        research, and community service, etc., are rigorously reviewed and
        screened by our team members.
      </div>
      <div class="text">
        EAmaster is committed to the prosperity of adolescents. Our print and
        online content facilitates students, parents, and educators in locating
        extracurricular activities in China and abroad that match the interests
        of students. In addition to academic achievements, we believe
        extracurricular activities showcase a student's uniqueness. They
        maximize the students' learning potential and promotes their personal
        developments, and are therefore, an integral part of their high school
        careers.
      </div>
      <div class="text">
        EAmaster makes itself different from other extracurricular activity
        platforms in China through its unique approach of solving the
        information asymmetry problem. We provide comprehensive and objective
        information to students, parents, and secondary school counselors as an
        impartial third party.
      </div>
      <div class="text">
        Our extensive partnerships with prestigious universities and
        organizations further validate our credibility and ensure that students
        have access to high-quality opportunities. Moreover, our team composed
        of graduates from Columbia University, UCLA, Harvard, and NYU screen and
        validate the extracurricular activities we promote through a rigorous
        process. We work closely with organizers to verify the credibility and
        significance of each activity. We ensure that the information we provide
        is trustworthy and reliable.
      </div>
    </div>
    <div class="bg3">
      <img src="@/assets/icon_star@2x.png" alt="" />
      <p>
        How it<br />
        works for YOU
      </p>
    </div>

    <div class="bg4">
      <div class="text">
        Constantly collaborating with universities, high schools, and other
        activity organizers, we provide up-to-date information regarding summer
        programs, academic competitions, community services, etc. At EAmaster,
        we've made it simple for students of all backgrounds to find
        extracurricular activities that align with their interests and skills.
      </div>
      <div class="text">
        You can search for programs directly on eamaster.com in two methods.
        First, users can conduct keyword searches, similar to Baidu and other
        search engines. To accomplish this, navigate to EAmaster's homepage bar
        and enter the categories of programs you're seeking in the search bar.
        Additionally, you can filter programs by study area, start date, grade,
        and more.
      </div>
      <div class="text">
        Educators are encouraged to contact us in order to receive a
        complimentary copy of the "Activity White Paper," an annual directory
        containing a selection of activities from various categories.
      </div>
    </div>
  </div>
</template>

<script>
export default {}
</script>

<style lang="less" scoped>
.bg1 {
  margin: 0 auto;
  width: 1920px;
  height: 720px;
  background: url('~@/assets/pic_bg_1@2x.png') no-repeat top center/cover;
}
.about {
  width: 1920px;
  padding: 100px 360px 0px 360px;
  margin: 0 auto;
  height: 804px;
  background: url('~@/assets/about us@2x.png') no-repeat top center/cover;
  .title {
    font-size: 54px;
    font-family: Arial-Black, Arial;
    font-weight: 900;
    color: #333333;
    line-height: 76px;
    margin-bottom: 20px;
    text-align: center;
  }
}
.bg3 {
  width: 1920px;
  margin: 0 auto;
  height: 638px;
  background: url('~@/assets/pic_bg_3@2x.png') no-repeat top center/cover;
  padding: 200px 0px 0px 360px;
  img {
    width: 68px;
    height: 68px;
    margin-bottom: 40px;
  }
  p {
    font-size: 54px;
    font-family: Arial-Black, Arial;
    font-weight: 900;
    color: #ffffff;
    line-height: 76px;
  }
}
.bg4 {
  width: 1920px;
  margin: 0 auto;
  height: 871px;
  background: url('~@/assets/pic_bg_4@2x.png') no-repeat top center/cover;
  padding: 80px 360px 0px 360px;
  .text {
    font-size: 18px;
    font-family: Alibaba-PuHuiTi-R, Alibaba-PuHuiTi;
    font-weight: normal;
    color: #333333;
    line-height: 28px;
    margin-bottom: 20px;
  }
}
.text {
  font-size: 18px;
  font-family: Alibaba-PuHuiTi-R, Alibaba-PuHuiTi;
  font-weight: normal;
  color: #333333;
  line-height: 28px;
  margin-bottom: 20px;
}
</style>
